.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.App-link {
  color: #61dafb;
}
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color:#f0f2f2;
}
.container {
  width: 100%;
  margin: 0 auto;
}

header {
  /* background: linear-gradient(135deg, #7b2ff7, #f107a3); */
  background: linear-gradient(135deg, rgb(123, 47, 247, 0.8), rgb(241, 7, 163, 0.8));
  color: white;
  padding: 20px 0;
  text-align: center;
}
section {
  padding: 50px 0;
  text-align: center;
}
.coming-soon {
  background: #f107a3;
  color: white;
  padding: 10px 20px;
  display: inline-block;
  margin-top: 20px;
  border-radius: 20px;
}
.ai-model {
  background: white; 
  /* #e0d7f5; */
  margin-bottom: 0px ;
  margin-left:0px;
  margin-right:0px;
  width:100vw;
  /* border:solid;
  border-width: 1px; */
}
.cta {
  background: #7b2ff7;
  color: white;
  padding: 30px 0;
}
.cta button {
  background: #f107a3;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}
footer {
  background: #3f3d56;
  color: white;
  padding: 20px 0;
  text-align: center;
}



.button_gen {
  background-color:#61dafb;
}

.button_toolbar {
  background-color:#b861fb;
}

.button_2 {
  background-color:#771e16;
}



.tablestyle {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid;
  overflow-x:auto;
  margin-top:2px;
}


th, td {
  text-align: left;
  padding: 8px;
  margin:10px;
  border: 1px solid;
  border-color: grey;
}

td {
  width: 10%;
  min-width:100px;
  font-weight: 600;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
